import { Trans } from "@lingui/macro";
import { Box, Button, Card, CardActions, CardContent, List, ListItem, ListItemButton, ListItemText, Typography } from "@mui/material";
import { ThemeSettingsContext } from "../context/themesettingscontext";
import React, { useContext } from "react";
import { LanguageContext } from "../context/languagecontext";
import { ThemeContext } from "../context/themecontext";
import { UserContext } from "../context/usercontext";
import { HearderUI } from "../components/header";
import { FixedSizes } from "../constants/constants";
import { IntegrationSettingsContext } from "../context/integrationsettingscontext";
import { LanguageCodes, SettingPageItems, ThemeCodes, WidgetSize } from "../types/enum";
import { ThreeTabs, TwoTabs } from "../components/tabs";
import { useLocation, useNavigate } from "react-router-dom";
import { APIContext } from "../context/apicontext";

function Account(Props: any) {
	const navigate = useNavigate();
	let loc = useLocation().pathname;
	if (loc.charAt(0) === "/") loc = loc.substring(1);
	if (loc === "") loc = "benefits";
	const authContext = useContext(APIContext);

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		navigate("/");
		authContext.logout();
	};
	return (
		<Box sx={{ textAlign: "center" }} onSubmit={handleSubmit} noValidate component="form" autoComplete="off">
			<Typography variant="body2" sx={{ color: (theme) => theme.palette.secondary.light }}>
				© 2015 - {new Date().getFullYear()} Loyall
			</Typography>
			<Typography variant="body2" sx={{ color: (theme) => theme.palette.secondary.light }}>
				<Trans>Version</Trans> {Props.Version}
			</Typography>
			<br />
			<Button
				sx={{
					bgcolor: (theme) => theme.palette.primary.dark,
					color: (theme) => theme.palette.text.secondary,
					border: (theme) => (theme.palette.mode === "light" ? `1px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.primary.dark}`),
					marginBottom: 2,
					height: "30px",
					width: "100%",
				}}
				type="submit"
				variant="contained"
				size="small"
				id="confirmall"
				name="confirmall"
				onClick={() => handleSubmit}
			>
				<Trans>Logout</Trans>
			</Button>
		</Box>
	);
}

function ThemeList() {
	const themeContext = useContext(ThemeContext);
	const IntegrationSetting = useContext(IntegrationSettingsContext);
	const availableThemes = IntegrationSetting.themes;
	const items = availableThemes.slice(0, 4);
	const handleListItemClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, item: string) => {
		themeContext.setCurrentTheme(item);
	};
	return (
		<Box sx={{}}>
			<List>
				{items.map(
					(x) =>
						x.IsActive && (
							<ListItem
								sx={{
									backgroundColor: ThemeCodes[x.Code] === themeContext.currentTheme ? (theme) => theme.palette.primary.light : "",
								}}
								disablePadding
								key={x.Code}
							>
								<ListItemButton
									selected={ThemeCodes[x.Code] === themeContext.currentTheme}
									onClick={(event) => handleListItemClick(event, ThemeCodes[x.Code])}
									sx={{
										color: (theme) => theme.palette.text.secondary,
									}}
								>
									<ListItemText primary={ThemeCodes[x.Code]} />
								</ListItemButton>
							</ListItem>
						)
				)}
			</List>
		</Box>
	);
}

function LanguageList() {
	const languageContext = useContext(LanguageContext);
	const IntegrationSetting = useContext(IntegrationSettingsContext);

	const languages = IntegrationSetting.languages;

	const handleListItemClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, item: string) => {
		languageContext.setLanguage(item);
	};

	return (
		<Box sx={{}}>
			<List>
				{languages.map(
					(x) =>
						x.IsActive && (
							<ListItem
								sx={{
									backgroundColor: LanguageCodes[x.Code] === languageContext.language ? (theme) => theme.palette.primary.light : "",
								}}
								disablePadding
								key={x.Code}
							>
								<ListItemButton
									selected={LanguageCodes[x.Code] === languageContext.language}
									onClick={(event) => handleListItemClick(event, LanguageCodes[x.Code])}
									sx={{ color: (theme) => (LanguageCodes[x.Code] === languageContext.language ? theme.palette.text.secondary : theme.palette.secondary.main) }}
								>
									<ListItemText primary={x.Description} />
								</ListItemButton>
							</ListItem>
						)
				)}
			</List>
		</Box>
	);
}

function SettingMain(Props: any) {
	let layout = Layouting();
	let minNavigationHight = 0;
	if (layout.fs) {
		delete layout["height"];
		delete layout["overflow"];

		const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
		minNavigationHight = 100 * (182 / vh);
	}

	return (
		<Card sx={layout}>
			<CardContent sx={{ padding: 0 }}>
				<HearderUI />
			</CardContent>
			<CardActions
				sx={{
					backgroundColor: (theme) => theme.palette.background.default,
					maxHeight: "246px",
					minHeight: () => {
						if (layout.fs) return `calc(100vh - ${minNavigationHight}vh)`;
					},
					overflow: "auto",
					pr: 2,
					pl: 2,
				}}
			>
				{Props.ShowThemes && (
					<ThreeTabs
						Label1={<Trans>Language</Trans>}
						Panel1={<LanguageList />}
						Label2={<Trans>Theme</Trans>}
						Panel2={<ThemeList />}
						Label3={<Trans>Profile</Trans>}
						Panel3={<Account Version={Props.Version} />}
					></ThreeTabs>
				)}
				{!Props.ShowThemes && (
					<TwoTabs
						Label1={<Trans>Language</Trans>}
						Panel1={<LanguageList />}
						Label2={<Trans>Profile</Trans>}
						Panel2={<Account Version={Props.Version} />}
					></TwoTabs>
				)}
			</CardActions>
		</Card>
	);
}

function SettingSmall(Props: any) {
	return (
		<Card sx={Layouting()}>
			<CardActions
				sx={{
					backgroundColor: (theme) => theme.palette.background.default,
					maxHeight: "180px",
					minHeight: "180px",
					overflow: "auto",
				}}
			>
				<Box sx={{ mt: 4, textAlign: "center", width: "100%" }}>
					<Typography
						sx={{
							color: (theme) => theme.palette.secondary.main,
						}}
						variant="h4"
					>
						<Trans>Settings</Trans>
					</Typography>
					<Typography
						sx={{
							color: (theme) => theme.palette.secondary.main,
						}}
						variant="h6"
					>
						<Trans>Language</Trans>
					</Typography>
					<LanguageList />
					<Typography variant="body2" sx={{ color: (theme) => theme.palette.secondary.light }}>
						© 2015 - 2023 Loyall
					</Typography>
					<Typography variant="body2" sx={{ color: (theme) => theme.palette.secondary.light }}>
						<Trans>Version</Trans> {Props.Version}
					</Typography>
				</Box>
			</CardActions>
		</Card>
	);
}

function SettingFull(Props: any) {
	return (
		<Card sx={{ borderRadius: "unset", overflow: "none" }}>
			<CardContent sx={{ padding: 0 }}>
				<HearderUI />
			</CardContent>
			<CardActions
				sx={{
					backgroundColor: (theme) => theme.palette.background.default,
					height: window.innerHeight - FixedSizes.TOPBAR - FixedSizes.HEADER - FixedSizes.NAVBAR,
					overflow: "scroll",
					pr: 2,
					pl: 2,
				}}
			>
				{Props.ShowThemes && (
					<ThreeTabs
						Label1={<Trans>Language</Trans>}
						Panel1={<LanguageList />}
						Label2={<Trans>Theme</Trans>}
						Panel2={<ThemeList />}
						Label3={<Trans>Profile</Trans>}
						Panel3={<Account Version={Props.Version} />}
					></ThreeTabs>
				)}
				{!Props.ShowThemes && (
					<TwoTabs
						Label1={<Trans>Language</Trans>}
						Panel1={<LanguageList />}
						Label2={<Trans>Profile</Trans>}
						Panel2={<Account Version={Props.Version} />}
					></TwoTabs>
				)}
			</CardActions>
		</Card>
	);
}

export function SettingUI(props: any) {
	let showThemes = false;
	if (props.IntegrationSetting.settingPageItems.includes(SettingPageItems.Theme)) {
		showThemes = true;
	}
	const version = "2.0/2025";
	switch (props.userContext.widgetSizeState()) {
		case WidgetSize.Main:
			return <SettingMain ShowThemes={showThemes} Version={version} />;
		case WidgetSize.Small:
			return <SettingSmall ShowThemes={showThemes} Version={version} />;
		case WidgetSize.Full:
			return <SettingFull ShowThemes={showThemes} Version={version} />;
		default:
			return null;
	}
}

function Layouting() {
	const widgetSetting = useContext(ThemeSettingsContext);
	let state = null;
	if (widgetSetting.overflow) {
		state = {
			height: "fit-content",
			overflow: "auto",
			boxShadow: "0",
		};
	}
	state = {
		...state,
		borderRadius: "0",
		fs: widgetSetting.floatInMobile,
	};
	return state;
}
