import {
	AuthResponseDTO,
	CardDTO,
	ConsentType,
	CreateMrcTokenResponse,
	MemberBonusInfoDto,
	MemberConsentDTO,
	MemberDTO,
	MemberLoginRequestDTO,
	SupportTicketDto,
	StoreboxIframeRequestDTO,
	TermsOfServiceDTO,
	TicketReplyRequestDto,
	TransactionDto,
	UpdateMemberProfileResponseDTO,
	VoucherDto,
	FaqDto,
} from "../apiclient/MemberAPI";
import { AuthenticationTypes, AuthState, LoginType, RedeemType, WidgetSize } from "./enum";
import { Cart, Product } from "./type";

export const emailPattern = new RegExp(
	/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

export const bankAccountPattern = new RegExp(/\d{4}.\d{2}.\d{5}/g);
export interface WidgetFunctions {
	ChangeSize: (widgetSize: WidgetSize) => WidgetSize;
	Close: () => void;
	Open: () => void;
	CartTotalPrice: (amount: number) => void;
	Logout: () => boolean;
	InitializeLogin: (phoneNumber: string, type: LoginType) => void;
	SetLanguage: (lang: string) => void;
	SetCart: (cart: Cart) => void;
	SetProduct: (product: Product) => boolean;
	UpdateCards: (loadAPI: boolean) => void;
	UpdateBenefits: (loadAPI: boolean) => Promise<boolean>;
	UpdateConsents: (loadAPI: boolean) => Promise<boolean>;
}

export interface AuthenticationRequest {
	Type: AuthenticationTypes;
	Phone?: string;
	VerificationCode?: string;
	VippsCode?: string;
	Token?: string;
}

export interface ILoyallApiService {
	CreateVerificationCode: (programId: number, phoneNumber: string) => Promise<void>;
	CreateTokenByMRC: (MRC: string) => Promise<CreateMrcTokenResponse>;
	Authenticate: (request: AuthRequest) => Promise<AuthResponseDTO> | null;
	GetMemberInfo: (token: string | null) => Promise<MemberDTO | null>;
	GetBonusInfo: (token: string | null) => Promise<MemberBonusInfoDto[]>;
	GetVoucherInfo: (token: string | null) => Promise<VoucherDto[]>;
	UpdateMemberInfo: (token: string | null, params: MemberDTO, programId: number) => Promise<UpdateMemberProfileResponseDTO>;
	GetTermsOfService: (programId: number, language: string) => Promise<TermsOfServiceDTO>;
	GetReceiptInfo: (token: string | null) => Promise<TransactionDto[]>;
	GetCardInfo: (token: string | null) => Promise<CardDTO[]>;
	SetCardByFrame: (token: string | null, params: StoreboxIframeRequestDTO) => Promise<string>;
	SetCardByBankAxept: (token: string | null, accountNumber?: string) => Promise<string>;
	GetVippsRedirect: (returnURL?: string) => Promise<string>;
	ApplyBonusOverTime: (
		token: string | null,
		merchantId?: number,
		amount?: number,
		redeemedBy?: string,
		redeemedByEmail?: string,
		reason?: string
	) => Promise<void>;
	ApplyVoucherOverTime: (token: string | null, voucherRef: string, redeemedBy: string, reason: RedeemType) => Promise<void>;
	GetMemberConsent: (token: string | null) => Promise<MemberConsentDTO[]>;
	UpdateMemberConsent: (token: string | null, consentType: ConsentType, create: boolean) => Promise<void>;
	DeleteMemberCard: (token: string | null, cardId: string) => Promise<string>;
	GetTicketsInfo: (token: string | null) => Promise<SupportTicketDto[]>;
	CreateTicket: (token: string | null, request: SupportTicketDto) => Promise<string>;
	ReplyTicket: (token: string | null, reply: TicketReplyRequestDto) => Promise<string>;
	GetFaqInfo: (token: string | null, language: string) => Promise<FaqDto[]>;
}

export interface LoginResponse {
	authstate: AuthState;
	token?: string | null;
}

export interface AuthRequest {
	Type: AuthenticationTypes;
	Data?: MemberLoginRequestDTO;
	VippsCode?: string;
	ProgramId?: number;
	JWT?: string;
}

export interface IStorage {
	prefix: string;
	setItem: (k: string, v: string) => void;
	getItem: (k: string) => string | null;
	removeItem: (k: string) => void;
}

export interface JWTResponseModel {
	ProgramId: string;
	aud: string;
	email: string;
	exp: number;
	http: string;
	iat: number;
	iss: string;
	name: string;
	nameid: string;
	nbf: number;
	role: string;
}

export interface IThemeSettings {
	positionFull: any;
	positionDynamic: any;
	positionFix: any;
	positionClose: any;
	positionMinimize: any;
	overflow: boolean;
	floatInMobile: boolean;
}
