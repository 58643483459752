import { useState } from "react";

export const sendData = async (phone: string, password: string, programId: number, token: string, ghaConsent: boolean, smsConsent: boolean, emailConsent: boolean, offerConsent: boolean) => {
	const url = "https://gha.loyalty.loyco.io/api/v1/createGhaMember/";

	const data = {
		phone,
		password,
		programId,
		ghaConsent,
		smsConsent,
		emailConsent,
		offerConsent
	};

	const headers = {
		"Content-Type": "application/json",
		Authorization: `Bearer ${token}`,
	};

	const curlCommand = `curl -X POST ${url} \\
	    -H "Content-Type: application/json" \\
	    -H "Authorization: Bearer ${token}" \\
	    -d '${JSON.stringify(data)}'`;
	// console.log(curlCommand);
	try {
		const response = await fetch(url, {
			method: "POST",
			headers: headers,
			body: JSON.stringify(data),
		});

		if (response.ok) return "GHA";

		const responseData = await response.json();
		let errorMessages = "UnknownError";
		if (responseData?.error_list && Array.isArray(responseData.error_list)) {
			errorMessages = responseData.error_list.join(", ");
		}

		return `${response.status}|${errorMessages}`;
	} catch (error) {
		console.error("Error sending data:", error);
		return "exception";
	}
};
