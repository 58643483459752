import { Constants } from "../constants/constants";

export function StoreboxURLBuilder(): string {
	const temp = window.location.href.split("#")[0];
	const redirect = new URL(temp);
	redirect.searchParams.append(Constants.StoreboxSearchParam, Constants.StoreboxRedirect);
	return redirect.href;
}

export function groupBy<T>(arr: T[], fn: (item: T) => any) {
	return arr.reduce<Record<string, T[]>>((prev, curr) => {
		const groupKey = fn(curr);
		const group = prev[groupKey] || [];
		group.push(curr);
		return { ...prev, [groupKey]: group };
	}, {});
}

export function ghaPasswordValidator(password: string | null | undefined): boolean {
	if (!password) return false;

	const minLength = 8;
	const hasUpperCase = /[A-Z]/.test(password);
	const hasLowerCase = /[a-z]/.test(password);
	const hasSpecialCharacter = /[^A-Za-z0-9"]/.test(password); // Excludes numbers and letters, but allows other characters
	const hasNumber = /\d/.test(password); // Check for at least one number
	const hasNoSpaces = !/\s/.test(password);
	return password.length >= minLength && hasUpperCase && hasLowerCase && hasSpecialCharacter && hasNumber && hasNoSpaces;
}
