import { log } from "../service/clientlog";
import { HashRouter, Route, Routes } from "react-router-dom";
import BottomNavBar from "../navbar";
import * as Setting from "../pages/settings";
import CloseUI from "../pages/closed";
import { BonusUI } from "../pages/benefits";
import * as Profile from "../pages/profile";
import { APIContext } from "../context/apicontext";
import { Box, Paper } from "@mui/material";
import { ThemeSettingsContext } from "../context/themesettingscontext";
import { useContext, useEffect, useState } from "react";
import { LoginUI } from "../unauthorizedpages/login";
import { ConsentUI } from "../unauthorizedpages/consent";
import { VerificationUI } from "../unauthorizedpages/verification";
import { UserContext } from "../context/usercontext";
import { TopBarIcons } from "../components/topbar";
import { MinimizeUI } from "../pages/minimize";
import * as Purchases from "../pages/purchases";
import { SSO } from "../unauthorizedpages/sso";
import { SSOCallbackHandler } from "../service/ApiService";
import { IntegrationSettingsContext } from "../context/integrationsettingscontext";
import { WelcomeUI } from "../pages/welcome";
import { AuthState, ConsoleType, WidgetMode, WidgetSize } from "../types/enum";
import { checkToken } from "../service/jwt";
import { BankUI } from "../unauthorizedpages/bank";
import { SupportUI } from "../pages/support";

export function LoginRouter() {
	const widgetSettingsCtx = useContext(ThemeSettingsContext);
	const authContext = useContext(APIContext);
	const IntegrationSetting = useContext(IntegrationSettingsContext);
	let elem: any;
	const currentAuthState = authContext.authState();
	switch (currentAuthState) {
		case AuthState.Unauthorized:
			elem = <LoginUI />;
			break;
		case AuthState.Authorized:
			elem = <BonusUI />;
			break;
		case AuthState.PendingSSOVerification:
			const callbackURL = SSOCallbackHandler();
			elem = callbackURL.Token && callbackURL.Type ? <SSO SSOType={callbackURL.Type} SSOCode={callbackURL.Token} /> : <LoginUI />;
			break;
		case AuthState.SSOVerificationFailed:
			elem = <SSO SSOType="" SSOCode="" />;
			break;
		case AuthState.PendingConsent:
			elem = <ConsentUI />;
			break;
		case AuthState.PendingBank:
			elem = <BankUI />;
			break;
		case AuthState.Unregistered:
		case AuthState.MemberVerificationFailed:
		case AuthState.NonMemberVerificationFailed:
		case AuthState.PendingVerification:
			elem = <VerificationUI />;
			break;
		default: {
			log("XXX invalid authentication state -- what to render?", ConsoleType.Error, currentAuthState);
			elem = <LoginUI />;
			break;
		}
	}
	const [width, setWidth] = useState(window.innerWidth);
	const handleWindowSizeChange = () => {
		setWidth(window.innerWidth);
	};

	useEffect(() => {
		window.addEventListener("resize", handleWindowSizeChange);
		return () => {
			window.removeEventListener("resize", handleWindowSizeChange);
		};
	}, []);
	const IsMobile = width <= 1024;
	let customizedState = widgetSettingsCtx.positionFix as string[];
	const isFullscreen = (customizedState.findIndex((x) => x === "height:100vh") > 0) as boolean;
	if (IsMobile) {
		customizedState.splice(
			customizedState.findIndex((x) => x.startsWith("width")),
			1
		);
		customizedState.splice(
			customizedState.findIndex((x) => x.startsWith("minWidth")),
			1
		);
		customizedState.splice(
			customizedState.findIndex((x) => x.startsWith("maxWidth")),
			1
		);
	}

	if (currentAuthState === AuthState.PendingConsent || currentAuthState === AuthState.PendingBank)
		if (!checkToken(window.localStorage.getItem(`${IntegrationSetting.storageId}_token`) ?? "")) authContext.logout();

	var customizedStyle = Object.fromEntries(customizedState.map((s) => s.split(":")));
	const sx = {
		width: IsMobile ? "90%" : "unset",
		mb: IsMobile ? "4em" : "unset",
		ml: IsMobile ? "auto" : "unset",
		mr: IsMobile ? "auto" : "unset",
		...customizedStyle,
	};

	if (widgetSettingsCtx.floatInMobile) {
		return <Paper sx={sx}>{elem}</Paper>;
	}

	return (
		<Paper
			sx={{
				borderBottomLeftRadius: (theme) => {
					return !IsMobile ? theme.shape.borderRadius : 0;
				},
				borderBottomRightRadius: (theme) => {
					return !IsMobile ? theme.shape.borderRadius : 0;
				},
				borderRadius: () => {
					if (isFullscreen) return 0;
				},
				...customizedStyle,
			}}
		>
			{elem}
		</Paper>
	);
}

export function MinimizeRouter(Props: any) {
	const userContext = useContext(UserContext);

	let elem: any;

	switch (userContext.widgetSizeState()) {
		case WidgetSize.Minimize: {
			elem = <MinimizeUI Product={Props.product} IconSize="small" />;
			break;
		}
		default: {
			log("Handle new size here", ConsoleType.Error, userContext.widgetSizeState());
			elem = null;
			break;
		}
	}

	const widgetSettingsCtx = useContext(ThemeSettingsContext);
	const state = widgetSettingsCtx.positionMinimize;

	return <Paper sx={{ maxWidth: window.innerWidth <= 1024 ? "unset" : "350px", height: "80px", borderRadius: "100px", padding: 2, ...state }}>{elem}</Paper>;
}

export function WelcomeRouter() {
	const authContext = useContext(APIContext);
	const IntegrationSetting = useContext(IntegrationSettingsContext);
	const currentAuthState = authContext.authState();
	if (currentAuthState === AuthState.PendingConsent || currentAuthState === AuthState.PendingBank)
		if (!checkToken(window.localStorage.getItem(`${IntegrationSetting.storageId}_token`) ?? "")) authContext.logout();

	return (
		<Box
			sx={{
				width: "90%",
				marginBottom: () => {
					return window.innerWidth < 1024 ? "4em" : "auto";
				},
				marginLeft: () => {
					return window.innerWidth < 1024 ? "auto" : "auto";
				},
				// marginTop: () => {
				// 	return window.innerWidth < 1024 ? "2em" : "auto";
				// },
				marginRight: () => {
					return window.innerWidth < 1024 ? "auto" : "auto";
				},
				left: () => {
					return window.innerWidth < 1024 ? "auto" : "2em";
				},
				position: () => {
					return window.innerWidth < 1024 ? "auto" : "relative";
				},
			}}
		>
			<WelcomeUI />
		</Box>
	);
}

export function CloseRouter(Props: any) {
	const userContext = useContext(UserContext);

	let elem: any;

	switch (userContext.widgetModeState()) {
		case WidgetMode.Close: {
			elem = <CloseUI AssetsURL={Props.AssetsURL} programID={Props.programID} />;
			break;
		}
		default: {
			log("Handle new state here", ConsoleType.Error, userContext.widgetModeState());
			elem = <LoginUI />;
			break;
		}
	}

	const widgetSettingsCtx = useContext(ThemeSettingsContext);
	const state = widgetSettingsCtx.positionClose;

	return <Paper sx={state}>{elem}</Paper>;
}

export function WidgetRouter() {
	const widgetSettingsCtx = useContext(ThemeSettingsContext);
	const userContext = useContext(UserContext);
	const IntegrationSetting = useContext(IntegrationSettingsContext);
	const authContext = useContext(APIContext);

	const GHAModuleIsEnabled = IntegrationSetting.authRequiredPassword;
	if (GHAModuleIsEnabled)
		window.location.href = 'https://www.ghadiscovery.com/'

	if (!checkToken(window.localStorage.getItem(`${IntegrationSetting.storageId}_token`) ?? "")) authContext.logout();

	if (IntegrationSetting.postLoginWidgetSize && Object.keys(Object.values(WidgetSize)).includes(IntegrationSetting.postLoginWidgetSize?.toString() ?? "")) {
		userContext.changeWidgetSize(Object.values(Object.keys(WidgetSize))[Number(IntegrationSetting.postLoginWidgetSize)] as WidgetSize);
		IntegrationSetting.postLoginWidgetSize = null;
	}
	if (IntegrationSetting.postLoginWidgetMode && Object.keys(Object.values(WidgetMode)).includes(IntegrationSetting.postLoginWidgetMode?.toString() ?? "")) {
		userContext.toggleWidgetMode(Object.values(Object.keys(WidgetMode))[Number(IntegrationSetting.postLoginWidgetMode)] as WidgetMode);
		IntegrationSetting.postLoginWidgetMode = null;
	}
	let position: string[] = [];
	let style = {};
	let smallSizeComponent: any;
	const ws = userContext.widgetSizeState();
	switch (ws) {
		case WidgetSize.Full:
			widgetSettingsCtx.overflow = false;
			position = widgetSettingsCtx.positionFull;
			style = {};
			break;
		case WidgetSize.Small:
			widgetSettingsCtx.overflow = true;
			position = widgetSettingsCtx.positionDynamic;
			smallSizeComponent = <BonusUI />;
			break;
		case WidgetSize.Main:
			position = widgetSettingsCtx.positionFix;
			style = {
				height: "calc(100% - 56px)",
				overflow: "auto",
			};
			break;
		default:
			break;
	}
	const [width, setWidth] = useState(window.innerWidth);
	const [height, setHeight] = useState(window.innerHeight);
	const handleWindowSizeChange = () => {
		setWidth(window.innerWidth);
		setHeight(window.innerHeight);
	};

	useEffect(() => {
		window.addEventListener("resize", handleWindowSizeChange);
		return () => {
			window.removeEventListener("resize", handleWindowSizeChange);
		};
	}, []);
	const IsMobile = width <= 1024;
	if (IsMobile) {
		position.splice(
			position.findIndex((x) => x.startsWith("width")),
			1
		);
		position.splice(
			position.findIndex((x) => x.startsWith("minWidth")),
			1
		);
		position.splice(
			position.findIndex((x) => x.startsWith("maxWidth")),
			1
		);
	}
	if (IsMobile && ws === WidgetSize.Full) {
		position.splice(
			position.findIndex((x) => x.startsWith("width")),
			1
		);
		position.splice(
			position.findIndex((x) => x.startsWith("minWidth")),
			1
		);
		position.splice(
			position.findIndex((x) => x.startsWith("maxWidth")),
			1
		);
		const a = position.splice(
			position.findIndex((x) => x.startsWith("height")),
			1,
			`height: ${height}px`
		);
		if (a.length === 0) position.push(`height: ${height}px`);
		const b = position.splice(
			position.findIndex((x) => x.startsWith("borderRadius")),
			1,
			`borderRadius: ${0}`
		);
		if (b.length === 0) position.push(`borderRadius: ${0}`);
	}
	if (IsMobile && ws === WidgetSize.Small) {
		position.splice(
			position.findIndex((x) => x.startsWith("height")),
			1,
			`height: `
		);
		position.push(`overflow: 100px`);
	}
	const height100vh = position.findIndex((x) => x === "height:100vh");
	const heightFitContent = position.findIndex((x) => x === "height:fit-content");
	if (!IsMobile && height100vh >= 0 && ws === WidgetSize.Full) {
		const parent = document.getElementById(IntegrationSetting.container);
		if (parent) {
			parent.style.bottom = "0";
			position.splice(
				position.findIndex((x) => x.startsWith("minWidth")),
				1,
				`minWidth: ${widgetSettingsCtx.positionFix.minWidth}`
			);
			position.splice(
				position.findIndex((x) => x.startsWith("maxWidth")),
				1,
				`maxWidth: ${widgetSettingsCtx.positionFix.maxWidth}`
			);
		}
	}
	if (!IsMobile && height100vh > 0 && ws === WidgetSize.Main) {
		const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
		const navigationHight = 100 * (34 / vh);
		position.splice(
			position.findIndex((x) => x.startsWith("height")),
			1,
			`height: calc(100vh - ${navigationHight}vh)`
		);
		position.splice(
			position.findIndex((x) => x.startsWith("borderRadius")),
			1,
			`borderRadius: ${0}`
		);
	}
	if (!IsMobile && heightFitContent > 0 && ws === WidgetSize.Main && widgetSettingsCtx.floatInMobile) {
		const parent = document.getElementById(IntegrationSetting.container);
		if (parent) {
			parent.style.bottom = "0";
		}
	}

	var customizedStyle = Object.fromEntries(position.map((s) => s.split(":")));

	if (smallSizeComponent && !IsMobile)
		return (
			<Paper sx={customizedStyle}>
				<HashRouter>
					<TopBarIcons Visibility={true} IconSize="small" IconName="loyall" Transform="scale(1);" />
					{smallSizeComponent}
					<BottomNavBar size={ws} isMobile={IsMobile || widgetSettingsCtx.floatInMobile} />
				</HashRouter>
			</Paper>
		);
	return (
		<Paper sx={customizedStyle}>
			<HashRouter>
				<TopBarIcons Visibility={true} IconSize="small" IconName="loyall" Transform="scale(1);" />
				<Box sx={style}>
					<Routes>
						<Route path="/benefits" element={<BonusUI />} />
						<Route path="/profile" element={<Profile.ProfileUI />} />
						<Route path="/purchases" element={<Purchases.PurchasesUI />} />
						<Route path="/settings" element={<Setting.SettingUI userContext={userContext} IntegrationSetting={IntegrationSetting} />} />
						<Route path="/support" element={<SupportUI />} />
						<Route path="/" element={<Profile.ProfileUI />} />
					</Routes>
				</Box>
				<BottomNavBar size={ws} isMobile={IsMobile || widgetSettingsCtx.floatInMobile} />
			</HashRouter>
		</Paper>
	);
}
