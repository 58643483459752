import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import { Trans } from "@lingui/macro";
import React, { FocusEvent, useContext, useEffect, useState } from "react";
import { APIContext } from "../context/apicontext";
import { ConsentType, MemberConsentDTO, MemberDTO } from "../apiclient/MemberAPI";
import { InputLabel, Link, TextField } from "@mui/material";
import { TopBarIcons } from "../components/topbar";
import CircularStatic from "../components/progress";
import { IntegrationSettingsContext } from "../context/integrationsettingscontext";
import { emailPattern } from "../types/interface";
import { Constants } from "../constants/constants";
import { log } from "../service/clientlog";
import { ConsoleType } from "../types/enum";
import { format } from "date-fns";
import { CssTextField } from "../pages/profile";
import { ghaPasswordValidator } from "../service/helper";
import { sendData } from "../service/gha";
import { t } from "@lingui/macro";

export function ConsentUI() {
	const memberContext = useContext(APIContext);
	const IntegrationSetting = useContext(IntegrationSettingsContext);
	const [processingAll, setProcessingAll] = useState<boolean>(false);
	const [processingSelected, setProcessingSelected] = useState<boolean>(false);

	const [sms, setSMS] = useState<boolean>(false);
	const [email, setEmail] = useState<boolean>(false);
	const [offer, setOffer] = useState<boolean>(false);
	const [consent, setConsent] = useState<MemberConsentDTO[] | null>([]);

	const getInitialInfo = (type: "email" | "birthdate" | "firstname" | "lastname") => {
		const member = memberContext.userInfo({ loadAPI: false });
		switch (type) {
			case "email":
				const incoming = new URLSearchParams(window.location.search).get(Constants.EmailSearchParam);
				if (incoming) return incoming;

				var emailAddress = member?.emailAddress;
				if (emailAddress && emailAddress !== undefined) return emailAddress;

				const temp =
					consent &&
					consent.filter((e) => {
						if (e.isValid && e.consentType === ConsentType.Email) return true;
						return false;
					});
				if (temp && temp.length > 0 && temp !== undefined) return temp[0].member?.emailAddress ?? "";
				return "";
			case "birthdate":
				var birthDate = member?.birthDate;
				if (birthDate && birthDate !== undefined) return birthDate;
				return "";
			case "firstname":
				var fname = member?.firstName;
				if (fname && fname !== undefined) return fname;
				return "";
			case "lastname":
				var lname = member?.lastName;
				if (lname && lname !== undefined) return lname;
				return "";
			default:
				return "";
		}
	};
	const [emailAddress, setEmailAddress] = useState(getInitialInfo("email"));
	const [birthDate, setBirthDate] = useState(getInitialInfo("birthdate"));
	const [birthDateError, setBirthDateError] = useState(false);

	const [password, setPassword] = useState("");
	const [firstname, setFirstname] = useState(getInitialInfo("firstname"));
	const [lastname, setLastname] = useState(getInitialInfo("lastname"));
	const [passwordError, setPasswordError] = useState(false);
	const [GHAError, setGHAError] = useState(false);
	const [ghaResult, setghaResult] = useState("");
	const [firstnameError, setFirstnameError] = useState(false);
	const [lastnameError, setLastnameError] = useState(false);
	const [gha, setGHA] = useState<boolean>(false);

	const [emailError, setEmailError] = useState(emailAddress.length > 0 && !emailPattern.test(emailAddress));
	const [smsError, setSMSError] = useState(false);

	const updateConsentItems = (consentItems: MemberConsentDTO[]) => {
		consentItems.forEach((element) => {
			if (element.isValid) {
				switch (element.consentType) {
					case ConsentType.SMS:
						setSMS(true);
						break;
					case ConsentType.Email:
						setEmail(emailAddress.length > 0 && true);
						break;
					case ConsentType.Offers:
						setOffer(true);
						break;
				}
			}
		});
	};
	useEffect(() => {
		const loadAsync = async () => {
			const temp = await memberContext.consentInfo({ loadAPI: false });
			setConsent(temp);
			updateConsentItems(temp);
		};
		loadAsync();
	}, []);

	const handlePasswordInputChange = (event: { target: { value: React.SetStateAction<string> } }) => {
		setPassword(event.target.value);
		setPasswordError(false);
	};
	const handleFirstnameInputChange = (event: { target: { value: React.SetStateAction<string> } }) => {
		setFirstname(event.target.value);
		setFirstnameError(false);
	};
	const handleLastnameInputChange = (event: { target: { value: React.SetStateAction<string> } }) => {
		setLastname(event.target.value);
		setLastnameError(false);
	};
	const handleBirthdateInputChange = (event: { target: { value: React.SetStateAction<string> } }) => {
		setBirthDate(event.target.value);
		setBirthDateError(false);
	};
	const handleEmailInputChange = (event: { target: { value: React.SetStateAction<string> } }) => {
		setEmailAddress(event.target.value);
	};
	const handleEmailBlur = (e: FocusEvent<HTMLInputElement>) => {
		const validity = emailPattern.test(emailAddress);
		setEmailError(!validity);
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		switch (event.target.name) {
			case "sms":
				setSMS(event.target.checked);
				setSMSError(!event.target.checked);
				break;
			case "email":
				setEmail(event.target.checked);
				break;
			case "offer":
				setOffer(event.target.checked);
				break;
			case "gha":
				setGHA(event.target.checked);
				break;
			default:
				break;
		}
	};

	const consentUserAgreement = (consentItems: MemberConsentDTO[]) => {
		const temp = consentItems.filter((e) => {
			if (e.isValid && e.consentType === ConsentType.UserAgreement) return true;
			return false;
		});
		if (temp && temp.length > 0) return true;
		return false;
	};

	const GHAModuleIsEnabled = IntegrationSetting.authRequiredPassword;

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const submitter = (event.nativeEvent as SubmitEvent).submitter?.id ?? "";
		if (smsError && submitter === "confirmselected") {
			return;
		}
		if (emailError && email) {
			return;
		}
		if (!sms && submitter === "confirmselected") {
			setSMSError(true);
			return;
		}
		if ((email && emailAddress.length <= 0) || (IntegrationSetting.authRequiredEmail && emailAddress.length <= 0)) {
			setEmailError(true);
			return;
		}
		if (IntegrationSetting.authRequiredBirthdate && !IntegrationSetting.optionalBirthdateOnConsent && (birthDate.length < 6 || isNaN(Date.parse(birthDate)))) {
			setBirthDateError(true);
			return;
		}
		try {
			switch (submitter) {
				case "confirmall":
					setProcessingAll(true);
					break;
				default:
					setProcessingSelected(true);
					break;
			}

			const data = new FormData(event.currentTarget);
			let sms = ((data.get("sms") as string) ?? "").includes("on");
			let email = ((data.get("email") as string) ?? "").includes("on");
			let offer = ((data.get("offer") as string) ?? "").includes("on");
			let gha = ((data.get("gha") as string) ?? "").includes("on");
			if (submitter === "confirmall") {
				setSMSError(false);
				setSMS(true);
				setEmail(true);
				setOffer(true);
				setGHA(true);
				sms = true;
				email = true;
				offer = true;
				gha = true;
			}
			if (submitter === "confirmall" && emailAddress.length <= 0) {
				setEmailError(true);
				setEmail(true);
				setOffer(true);
				setGHA(true);
				return;
			}

			const consent = await memberContext.consentInfo({ loadAPI: false });

			let originSMS = false,
				originEmail = false,
				originOffer = false;
			let isSMSChanged = false,
				isEmailChanged = false,
				isOfferChanged = false,
				isUserAgreementChanged = false;
			consent.forEach((item) => {
				if (item.isValid) {
					switch (item.consentType) {
						case ConsentType.SMS:
							originSMS = true;
							break;
						case ConsentType.Email:
							originEmail = true;
							break;
						case ConsentType.Offers:
							originOffer = true;
							break;
					}
				}
			});
			try {
				if (!originSMS) {
					await memberContext.updateConsent(ConsentType.SMS, true);
					isSMSChanged = true;
				}
				if (email !== originEmail) {
					await memberContext.updateConsent(ConsentType.Email, email);
					isEmailChanged = true;
				}
				if (offer !== originOffer) {
					await memberContext.updateConsent(ConsentType.Offers, offer);
					isOfferChanged = true;
				}
				if (!consentUserAgreement(consent) && (!IntegrationSetting.authRequiredCard || !IntegrationSetting.authRequiredAccount)) {
					await memberContext.updateConsent(ConsentType.UserAgreement, true);
					isUserAgreementChanged = true;
				}
			} catch (error) {
				log("Member consent update", ConsoleType.Error, sms, email, offer);
			} finally {
				if (isSMSChanged || isEmailChanged || isOfferChanged || isUserAgreementChanged) await memberContext.consentInfo({ loadAPI: true });
			}
			const cachedMember = memberContext.userInfo({ loadAPI: false }) as MemberDTO;
			const bday = birthDate ? new Date(birthDate).toISOString() : cachedMember.birthDate;
			if (GHAModuleIsEnabled && gha) {
				var validPass = ghaPasswordValidator(password);
				if (!validPass) {
					setPasswordError(true);
					return;
				}
				var validFirstname = firstname.length >= 2;
				if (!validFirstname) {
					setFirstnameError(true);
					return;
				}
				var validLastname = lastname.length >= 2;
				if (!validLastname) {
					setLastnameError(true);
					return;
				}
				var validEmail = emailPattern.test(emailAddress);
				if (!validEmail) {
					setEmailError(true);
					return;
				}
			}
			const member: MemberDTO = {
				id: cachedMember.id,
				address: cachedMember.address,
				birthDate: bday,
				cellphoneNumber: cachedMember.cellphoneNumber,
				firstName: firstname != null ? firstname : cachedMember.firstName?.trim() ? cachedMember.firstName : "",
				gender: cachedMember.gender,
				lastName: lastname != null ? lastname : cachedMember.lastName?.trim() ? cachedMember.lastName : "",
				programTag: cachedMember.programTag?.length === 0 ? IntegrationSetting.programDefaultTag ?? "" : cachedMember.programTag,
				userAgreementAcceptDate: cachedMember.userAgreementAcceptDate ?? new Date().toISOString(),
				emailAddress: emailAddress,
			};
			const gotoBank = IntegrationSetting.authRequiredCard || IntegrationSetting.authRequiredAccount;
			if (gotoBank) await memberContext.cardInfo({ loadAPI: false });

			if (GHAModuleIsEnabled) {
				await memberContext.updateGHAInfo(member);

				let ghaResult = "";
				let t = localStorage.getItem(`${IntegrationSetting.storageId}_token`);
				if (!t) {
					ghaResult = "no token";
					member.programTag = member.programTag + "_" + ghaResult;
					await memberContext.updateInfo(member, gotoBank, false);
					return;
				}

				try {
					var token = JSON.parse(t);
					ghaResult = await sendData(cachedMember.cellphoneNumber, password, Number(IntegrationSetting.programId), token, gha, sms, email, offer);
					member.programTag = member.programTag + "_" + ghaResult;

					if (ghaResult === "GHA") {
						await memberContext.updateInfo(member, gotoBank, false);
						window.location.replace("https://www.ghadiscovery.com/");
						return;
					}

					if (ghaResult !== "GHA") {
						const parts = ghaResult.split("|");
						let input = parts.length > 1 ? parts[1] : ghaResult;
						setghaResult(input);
						setGHAError(true);
						await memberContext.updateGHAInfo(member);
						setTimeout(async () => {
							await memberContext.updateInfo(member, gotoBank, false);
							window.location.replace("https://www.ghadiscovery.com/");
						}, 5000);
						return;
					}
				} catch (error) {
					console.log("Error in consent_gha:", error);
					ghaResult = "ConsentError" + error;
					member.programTag = member.programTag + "_" + ghaResult;
					await memberContext.updateInfo(member, gotoBank, false);
					return;
				}
			}

			await memberContext.updateInfo(member, gotoBank, false);
		} catch (error) {
			setProcessingAll(false);
			setProcessingSelected(false);
		} finally {
			setProcessingAll(false);
			setProcessingSelected(false);
		}
	};

	let smsText = t`I agree to communication via SMS.`;
	let emailText = t`I agree to communication via E-mail.`;
	let offerText = t`I'd like to receive personalized information.`;
	if (GHAModuleIsEnabled) {
		smsText = t`I agree to receive news and offers from Unike Hoteller via SMS.`;
		emailText = t`I agree to receive news and offers from Unike Hoteller via e-mail.`;
	}
	return (
		<React.Fragment>
			<TopBarIcons Visibility={IntegrationSetting.topbarOnLoginRouter} IgnoreResizeOnLoginRouter IconSize="small" IconName="loyall" Transform="scale(1);" />
			<Card
				sx={{
					background: "unset",
					color: "unset",
					borderBottomLeftRadius: "inherit",
					borderBottomRightRadius: "inherit",
					borderTopLeftRadius: 0,
					borderTopRightRadius: 0,
					boxShadow: 0,
				}}
			>
				<CardContent sx={{ padding: 0, color: (theme) => theme.palette.text.primary, pt: 2 }}>
					<Typography variant="h6">
						<Trans>Preferences</Trans>
					</Typography>
					<Typography variant="body2" gutterBottom>
						<Trans>Please update your consents.</Trans>
					</Typography>
				</CardContent>
				<CardActions sx={{ pt: 0, pb: 3.5, pr: 2, pl: 2 }}>
					<Box onSubmit={handleSubmit} noValidate component="form" autoComplete="off" sx={{ minWidth: "275px", maxWidth: "275px" }}>
						{IntegrationSetting.emailOnConsent && (
							<InputLabel
								shrink
								htmlFor="emailAddress"
								className="excludeLabel"
								sx={{ color: (theme) => theme.palette.text.primary, fontSize: (theme) => theme.typography.body2.fontSize }}
							>
								Email {GHAModuleIsEnabled && gha && `*`}
							</InputLabel>
						)}
						{IntegrationSetting.emailOnConsent && (
							<TextField
								required={IntegrationSetting.authRequiredEmail}
								focused
								defaultValue={emailAddress}
								sx={{
									backgroundColor: (theme) => theme.palette.background.default,
									"& .MuiOutlinedInput-root": {
										color: (theme) => theme.palette.secondary.main,
										height: "30px",
										"& fieldset": {
											borderRadius: 0.25,
										},
									},
									"& :-webkit-autofill": {
										WebkitBoxShadow: "0 0 0 1000px white inset",
										WebkitTextFillColor: "#000",
										borderRadius: "5px",
										maxHeight: "10px",
									},
									"& :autofill": {
										WebkitBoxShadow: "0 0 0 1000px white inset",
										WebkitTextFillColor: "#000",
										borderRadius: "5px",
										maxHeight: "10px",
									},
								}}
								id="emailAddress"
								size="small"
								name="emailAddress"
								type="email"
								autoComplete="email"
								fullWidth
								onChange={handleEmailInputChange}
								onBlur={handleEmailBlur}
								disabled={processingAll || processingSelected}
							/>
						)}
						{IntegrationSetting.authRequiredBirthdate && (
							<InputLabel
								shrink
								htmlFor="birthDate"
								className="excludeLabel"
								sx={{ mt: 1, color: (theme) => theme.palette.text.primary, fontSize: (theme) => theme.typography.body2.fontSize }}
							>
								<Trans>Birth Date</Trans>
							</InputLabel>
						)}
						{IntegrationSetting.authRequiredBirthdate && (
							<CssTextField
								required={!IntegrationSetting.optionalBirthdateOnConsent}
								defaultValue={birthDate && !birthDate.includes("1800") && format(new Date(birthDate), "yyyy-MM-dd")}
								inputProps={{
									type: "date",
								}}
								sx={{
									backgroundColor: (theme) => theme.palette.background.default,
									"& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
										color: "transparent",
									},
									"& .MuiOutlinedInput-root": {
										color: (theme) => theme.palette.secondary.main,
										height: "30px",
										"& fieldset": {
											borderRadius: 0.25,
										},
									},
									"& :-webkit-autofill": {
										WebkitBoxShadow: "0 0 0 1000px white inset",
										WebkitTextFillColor: "#000",
										borderRadius: "5px",
										maxHeight: "10px",
									},
									"& :autofill": {
										WebkitBoxShadow: "0 0 0 1000px white inset",
										WebkitTextFillColor: "#000",
										borderRadius: "5px",
										maxHeight: "10px",
									},
								}}
								variant="outlined"
								id="birthDate"
								size="small"
								name="birthDate"
								type="date"
								autoComplete="bday"
								InputLabelProps={{
									shrink: true,
								}}
								fullWidth
								onChange={handleBirthdateInputChange}
								disabled={processingAll || processingSelected}
							/>
						)}

						{GHAModuleIsEnabled && (
							<InputLabel
								shrink
								htmlFor="firstname"
								className="excludeLabel"
								sx={{ mt: 1, color: (theme) => theme.palette.text.primary, fontSize: (theme) => theme.typography.body2.fontSize }}
							>
								<Trans>First name</Trans> {GHAModuleIsEnabled && gha && `*`}
							</InputLabel>
						)}
						{GHAModuleIsEnabled && (
							<TextField
								required={true}
								defaultValue={firstname}
								inputProps={{
									type: "text",
									autoComplete: "given-name",
								}}
								sx={{
									backgroundColor: (theme) => theme.palette.background.default,
									"& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
										color: "transparent",
									},
									"& .MuiOutlinedInput-root": {
										color: (theme) => theme.palette.secondary.main,
										height: "30px",
										"& fieldset": {
											borderRadius: 0.25,
										},
									},
									"& :-webkit-autofill": {
										WebkitBoxShadow: "0 0 0 1000px white inset",
										WebkitTextFillColor: "#000",
										borderRadius: "5px",
										maxHeight: "10px",
									},
									"& :autofill": {
										WebkitBoxShadow: "0 0 0 1000px white inset",
										WebkitTextFillColor: "#000",
										borderRadius: "5px",
										maxHeight: "10px",
									},
								}}
								variant="outlined"
								id="firstname"
								size="small"
								name="firstname"
								type="text"
								InputLabelProps={{
									shrink: true,
								}}
								fullWidth
								onChange={handleFirstnameInputChange}
								disabled={processingAll || processingSelected}
							/>
						)}
						{GHAModuleIsEnabled && (
							<InputLabel
								shrink
								htmlFor="lastname"
								className="excludeLabel"
								sx={{ mt: 1, color: (theme) => theme.palette.text.primary, fontSize: (theme) => theme.typography.body2.fontSize }}
							>
								<Trans>Last name</Trans> {GHAModuleIsEnabled && gha && `*`}
							</InputLabel>
						)}
						{GHAModuleIsEnabled && (
							<TextField
								required={true}
								defaultValue={lastname}
								inputProps={{
									type: "text",
									autoComplete: "family-name",
								}}
								sx={{
									backgroundColor: (theme) => theme.palette.background.default,
									"& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
										color: "transparent",
									},
									"& .MuiOutlinedInput-root": {
										color: (theme) => theme.palette.secondary.main,
										height: "30px",
										"& fieldset": {
											borderRadius: 0.25,
										},
									},
									"& :-webkit-autofill": {
										WebkitBoxShadow: "0 0 0 1000px white inset",
										WebkitTextFillColor: "#000",
										borderRadius: "5px",
										maxHeight: "10px",
									},
									"& :autofill": {
										WebkitBoxShadow: "0 0 0 1000px white inset",
										WebkitTextFillColor: "#000",
										borderRadius: "5px",
										maxHeight: "10px",
									},
								}}
								variant="outlined"
								id="lastname"
								size="small"
								name="lastname"
								type="text"
								InputLabelProps={{
									shrink: true,
								}}
								fullWidth
								onChange={handleLastnameInputChange}
								disabled={processingAll || processingSelected}
							/>
						)}
						{GHAModuleIsEnabled && (
							<InputLabel
								shrink
								htmlFor="password"
								className="excludeLabel"
								sx={{ mt: 1, color: (theme) => theme.palette.text.primary, fontSize: (theme) => theme.typography.body2.fontSize }}
							>
								<Trans>Password</Trans> {GHAModuleIsEnabled && gha && `*`}
							</InputLabel>
						)}
						{GHAModuleIsEnabled && (
							<TextField
								required={true}
								inputProps={{
									type: "password",
								}}
								sx={{
									backgroundColor: (theme) => theme.palette.background.default,
									"& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
										color: "transparent",
									},
									"& .MuiOutlinedInput-root": {
										color: (theme) => theme.palette.secondary.main,
										height: "30px",
										"& fieldset": {
											borderRadius: 0.25,
										},
									},
									"& :-webkit-autofill": {
										WebkitBoxShadow: "0 0 0 1000px white inset",
										WebkitTextFillColor: "#000",
										borderRadius: "5px",
										maxHeight: "10px",
									},
									"& :autofill": {
										WebkitBoxShadow: "0 0 0 1000px white inset",
										WebkitTextFillColor: "#000",
										borderRadius: "5px",
										maxHeight: "10px",
									},
								}}
								variant="outlined"
								id="password"
								size="small"
								name="password"
								type="password"
								InputLabelProps={{
									shrink: true,
								}}
								fullWidth
								onChange={handlePasswordInputChange}
								disabled={processingAll || processingSelected}
							/>
						)}
						<Box sx={{ display: "flex", pt: 2.5 }}>
							<FormGroup>
								{GHAModuleIsEnabled && (
									<FormControlLabel
										sx={{
											pb: 1,
											ml: 0,
											"& p": { pl: 2 },
										}}
										control={
											<Switch
												sx={{
													"& .MuiSwitch-switchBase + .MuiSwitch-track": {
														backgroundColor: (theme) => theme.palette.secondary.main,
													},
												}}
												size="small"
												onChange={handleChange}
												name="gha"
												checked={gha}
												disabled={processingAll || processingSelected}
											/>
										}
										label={
											<Typography variant="body2">
												<Trans>I agree to receive news and offers from GHA DISCOVERY via Email.</Trans>
											</Typography>
										}
									/>
								)}
								<FormControlLabel
									sx={{
										pb: 1,
										ml: 0,
										"& p": { pl: 2 },
									}}
									control={
										<Switch
											sx={{
												"& .MuiSwitch-switchBase + .MuiSwitch-track": {
													backgroundColor: (theme) => theme.palette.secondary.main,
												},
											}}
											size="small"
											onChange={handleChange}
											name="sms"
											checked={sms}
											disabled={processingAll || processingSelected}
										/>
									}
									label={<Typography variant="body2">{smsText} *</Typography>}
								/>
								<FormControlLabel
									sx={{
										pb: 1,
										ml: 0,
										"& p": { pl: 2 },
									}}
									control={
										<Switch
											sx={{
												"& .MuiSwitch-switchBase + .MuiSwitch-track": {
													backgroundColor: (theme) => theme.palette.secondary.main,
												},
											}}
											size="small"
											onChange={handleChange}
											name="email"
											checked={email}
											disabled={processingAll || processingSelected}
										/>
									}
									label={<Typography variant="body2">{emailText}</Typography>}
								/>
								<FormControlLabel
									sx={{
										pb: 1,
										ml: 0,
										"& p": { pl: 2 },
									}}
									control={
										<Switch
											sx={{
												"& .MuiSwitch-switchBase + .MuiSwitch-track": {
													backgroundColor: (theme) => theme.palette.secondary.main,
												},
											}}
											size="small"
											onChange={handleChange}
											name="offer"
											checked={offer}
											disabled={processingAll || processingSelected}
										/>
									}
									label={<Typography variant="body2">{offerText}</Typography>}
								/>
							</FormGroup>
						</Box>
						{smsError && (
							<Box sx={{ pl: "3px" }}>
								<Typography variant="caption" display="block" gutterBottom color="warning.light">
									<Trans>SMS communication is required to complete your registration.</Trans>
								</Typography>
							</Box>
						)}
						{((emailError && email) || (emailError && IntegrationSetting.authRequiredEmail)) && (
							<Box sx={{ pl: "3px" }}>
								<Typography variant="caption" display="block" gutterBottom color="warning.light">
									<Trans>Please enter correct email address.</Trans>
								</Typography>
							</Box>
						)}
						{birthDateError && (
							<Box sx={{ pl: "3px" }}>
								<Typography variant="caption" display="block" gutterBottom color="warning.light">
									<Trans>Please enter your birthday.</Trans>
								</Typography>
							</Box>
						)}
						{passwordError && (
							<Box sx={{ pl: "3px" }}>
								<Typography variant="caption" display="block" gutterBottom color="warning.light">
									<Trans>Password must be at least 8 characters with upper/lower case and a special character (no double quotes, no space).</Trans>
								</Typography>
							</Box>
						)}
						{GHAError && (
							<Box sx={{ pl: "3px" }}>
								<Typography variant="caption" display="block" gutterBottom color="warning.light">
									{ghaResult}
								</Typography>
							</Box>
						)}
						{firstnameError && (
							<Box sx={{ pl: "3px" }}>
								<Typography variant="caption" display="block" gutterBottom color="warning.light">
									<Trans>Please enter your first name.</Trans>
								</Typography>
							</Box>
						)}
						{lastnameError && (
							<Box sx={{ pl: "3px" }}>
								<Typography variant="caption" display="block" gutterBottom color="warning.light">
									<Trans>Please enter your last name.</Trans>
								</Typography>
							</Box>
						)}
						<Box sx={{ display: "flex", flexDirection: "column", pt: 2 }}>
							<Button
								sx={{
									bgcolor: (theme) => theme.palette.primary.dark,
									color: (theme) => theme.palette.text.secondary,
									border: (theme) => (theme.palette.mode === "light" ? `1px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.primary.dark}`),
									marginBottom: 2,
									height: "30px",
								}}
								type="submit"
								variant="contained"
								size="small"
								id="confirmall"
								name="confirmall"
								onClick={() => handleSubmit}
								disabled={processingSelected || processingAll}
							>
								{processingAll && <CircularStatic visible={processingAll} />}
								{!processingAll && <Trans>Confirm all and verify profile</Trans>}
							</Button>
							<Link
								id="confirmselected"
								name="confirmselected"
								disabled={processingAll || processingSelected}
								textTransform={"unset"}
								href="#"
								underline="hover"
								variant="overline"
								component="button"
								onClick={() => handleSubmit}
								color="text.primary"
								sx={{
									color: (theme) => theme.palette.text.primary,
									fontSize: (theme) => theme.typography.subtitle1.fontSize,
									fontWeight: (theme) => theme.typography.h1.fontWeight,
									lineHeight: (theme) => theme.typography.h1.lineHeight,
								}}
							>
								{processingSelected && <CircularStatic visible={processingSelected} />}
								{!processingSelected && <Trans>Confirm selected and verify profile</Trans>}
							</Link>
						</Box>
					</Box>
				</CardActions>
			</Card>
		</React.Fragment>
	);
}
