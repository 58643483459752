import { Box, Stack, styled, Paper, Button, TextField, Typography, Link } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { ReactComponent as BonusIcon } from "../assets/resources/bonus.svg";
import { t, Trans } from "@lingui/macro";
import { MemberBonusInfoDto } from "../apiclient/MemberAPI";
import { useContext, useEffect, useState } from "react";
import { IntegrationSettingsContext } from "../context/integrationsettingscontext";
import { TextTemplate } from "../service/jsonreader";
import { hexToRGBA } from "../utils/themeStyling";
import { i18n } from "@lingui/core";
import { BonusResponseDTO } from "../types/type";
import { BenefitReleaseModes, BenefitTypes, Components, TemplateElements, WidgetSize } from "../types/enum";
import CircularStatic from "./progress";
import { BenefitTimerBox } from "./benefittimer";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: "transparent",
	...theme.typography.body2,
	textAlign: "center",
	color: theme.palette.text.secondary,
	boxShadow: "0 0",
}));

interface Props {
	bonusInfo: MemberBonusInfoDto | null;
	size: WidgetSize;
	update: (api: boolean) => Promise<void>;
}

const CssTextField = styled(TextField)(({ theme }) => ({
	"& label.Mui-focused": {
		color: theme.palette.secondary.light,
	},
	"& label": {
		color: theme.palette.secondary.light,
	},
	"& label:hover": {
		color: theme.palette.secondary.light,
	},
	"& .MuiOutlinedInput-root": {
		color: theme.palette.text.secondary,
		"& fieldset": {
			borderColor: theme.palette.secondary.dark,
			borderRadius: "5px",
		},
		"&:hover fieldset": {
			borderColor: theme.palette.secondary.light,
		},
		"&.Mui-focused fieldset": {
			borderWidth: 2,
			borderColor: theme.palette.secondary.light,
		},
	},
}));

export function BonusBox(props: Props) {
	const isCtx = useContext(IntegrationSettingsContext);
	const IntegrationSetting = useContext(IntegrationSettingsContext);
	const GetTextByTemplate = (level: number) => {
		let text = TextTemplate.GetTextTemplate(Components.Minimize, IntegrationSetting.customer, TemplateElements.texts, level, IntegrationSetting.product);
		if (text.length > 0) return text;
		return null;
	};
	const GetNoneReleasableText = (component: Components, level: number) => {
		let text = TextTemplate.GetTextTemplate(component, IntegrationSetting.customer, TemplateElements.texts, level, undefined);
		if (!text) return "";
		const translated = /*i18n*/ i18n._(`${text}`);
		return i18n._(translated) + " 🙂";
	};
	const ref = window.location.host;
	const [focus, setFocus] = useState(false);

	let bonusMaxVal = props.bonusInfo?.availableBonus ?? 0;
	bonusMaxVal = ~~bonusMaxVal; //Number(bonusMaxVal.toFixed(2));
	const [bonusUserInput, setBonusUserInput] = useState(0);
	const [error, setError] = useState(false);

	const [bonusRelease, setBonusRelease] = useState({ Message: "", Result: false, Value: "" });
	const [processing, setProcessing] = useState<boolean>(false);

	const [benefitByTimer, setBenefitByTimer] = useState<boolean>(false);
	useEffect(() => {
		if (!processing) {
			const res = isCtx.getBonusReleased(ref);
			if (res.Result) {
				const bonusReleaseResponseMSG = /*i18n*/ i18n._(`${res.Message}`);
				const bonusReleaseResponse = {
					Message: i18n._(bonusReleaseResponseMSG) + " 🙂",
					Result: res.Result,
					Value: res.Value,
				};
				setBonusRelease(bonusReleaseResponse);
			}
		}
	}, [processing, isCtx, ref]);

	const handleChange = (event: { target: { value: React.SetStateAction<string> } }) => {
		if (String(event.target.value).length > 0 && !Number(event.target.value)) {
			setBonusUserInput(0);
			setError(true);
			return;
		}
		if (event.target.value.toString().startsWith("0")) event.target.value = Number(event.target.value).toString();
		let temp = parseFloat(event.target.value.toString());
		if (temp > bonusMaxVal) temp = bonusMaxVal;
		if (temp < 0) temp = 0;
		setBonusUserInput(temp);
		setError(false);
	};

	const changeBonusRequested = async () => {
		isCtx.onEvent({ EventType: "deleteBonusReservation", Payload: { Ref: "DiscountCode", BonusUserInput: bonusUserInput } });
		setProcessing(true);
		await isCtx.deleteBonusReservation(ref);
		const temp: BonusResponseDTO = { Message: "", Result: false, Value: "" };
		setBonusRelease(temp);
		setProcessing(false);
	};

	const requestBonus = async (timer: boolean) => {
		if (!timer && bonusUserInput > 0) {
			isCtx.onEvent({ EventType: "onBonusReserved", Payload: { Ref: "Bonus", BonusUserInput: bonusUserInput } });
			setProcessing(true);
			const response = await isCtx.onBonusReserved(ref, bonusUserInput.toString());
			setProcessing(false);
			if (response as BonusResponseDTO) {
				const message = /*i18n*/ i18n._(`${response.Message}`);
				const translatedResponse: BonusResponseDTO = {
					Message: i18n._(message) + " 🙂",
					Result: response.Result,
					Value: response.Value,
				};
				setBonusRelease(translatedResponse);
			}
		}
		if (timer && bonusUserInput > 0) setBenefitByTimer(true);
	};
	const amountInput = t`amount`;
	const currency = IntegrationSetting.product.Currency ?? ",-";
	const releaseByTimer = IntegrationSetting.releaseMode === BenefitReleaseModes.ByTimer;
	const showNoReleasableText = !IntegrationSetting.enableReleaseBonus && IntegrationSetting.noReleasableWithText;

	return (
		<>
			{benefitByTimer && props.bonusInfo && (
				<BenefitTimerBox
					benefitType={BenefitTypes.Bonus}
					bonusInfo={props.bonusInfo}
					widgetSize={props.size}
					requestAmount={bonusUserInput}
					update={props.update}
				/>
			)}
			{!benefitByTimer && (
				<Box sx={{ height: processing ? "30px" : "unset", margin: processing ? "0 auto" : "unset", textAlign: processing ? "center" : "unset" }}>
					{processing && <CircularStatic visible={processing} />}
					{!processing && (
						<Stack spacing={0} sx={{}}>
							{bonusRelease.Result === false && (
								<Item
									sx={{
										pt: "27px",
										pl: "16px",
										pr: "16px",
										borderBottomRightRadius: 0,
										borderBottomLeftRadius: 0,
										backgroundColor: (theme) => {
											return hexToRGBA(theme.palette.background.paper, "0.1");
										},
									}}
								>
									<Stack
										direction="row"
										sx={{
											justifyContent: "space-between",
										}}
									>
										<Item
											sx={{
												color: (theme) => theme.palette.secondary.main,
												fontSize: (theme) => theme.typography.subtitle2.fontSize,
												fontWeight: (theme) => theme.typography.subtitle1.fontWeight,
												backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.00), rgba(255, 255, 255, 0.00))",
											}}
										>
											<Trans>{props.size === WidgetSize.Small ? GetTextByTemplate(0) : "Bonus"}</Trans>
										</Item>
										<Item sx={{ backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.00), rgba(255, 255, 255, 0.00))" }}>
											<InfoIcon
												sx={{
													fontSize: "14px",
													color: (theme) => {
														return theme.palette.background.paper;
													},
												}}
											/>
										</Item>
									</Stack>
								</Item>
							)}
							{bonusRelease.Result === false && (
								<Item
									sx={{
										pt: "3px",
										pl: "16px",
										pr: "16px",
										borderRadius: 0,
										backgroundColor: (theme) => {
											return hexToRGBA(theme.palette.background.paper, "0.1");
										},
									}}
								>
									<Stack direction="row" spacing={0}>
										<Item
											sx={{
												backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.00), rgba(255, 255, 255, 0.00))",
											}}
										>
											<BonusIcon />
										</Item>
										<Item
											sx={{
												color: (theme) => theme.palette.secondary.main,
												mt: "-3px",
												fontWeight: "600",
												fontSize: "20px",
												backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.00), rgba(255, 255, 255, 0.00))",
												pl: 1,
											}}
										>
											{bonusMaxVal}
											{currency}
										</Item>
									</Stack>
								</Item>
							)}
							{bonusRelease.Result === true && bonusRelease.Message.length > 0 && (
								<Item
									sx={{
										pt: "3px",
										pl: "16px",
										pr: "16px",
										borderRadius: "20px 20px 0px 0px !important",
										backgroundColor: (theme) => {
											return hexToRGBA(
												theme.palette.mode === "light" ? theme.palette.success.main : theme.palette.primary.main,
												theme.palette.tonalOffset.toString()
											);
										},
									}}
								>
									<Stack direction="row" spacing={0}>
										<Item
											sx={{
												pt: "19px",
												fontWeight: "bold",
												width: "100%",
												backgroundColor: (theme) => {
													return hexToRGBA(
														theme.palette.mode === "light" ? theme.palette.success.main : theme.palette.primary.main,
														theme.palette.mode === "light" ? "0" : "0.095"
													);
												},
											}}
										>
											<Typography>
												<Trans>Bonus Released</Trans>
											</Typography>
										</Item>
									</Stack>
									<Stack direction="row" spacing={0}>
										<Item
											sx={{
												mt: "1px",
												width: "100%",
												backgroundColor: (theme) => {
													return hexToRGBA(
														theme.palette.mode === "light" ? theme.palette.success.main : theme.palette.primary.main,
														theme.palette.mode === "light" ? "0" : "0.095"
													);
												},
											}}
										>
											<Typography variant="body2">
												{bonusRelease.Value}
												{currency} <Trans>discount.</Trans>
											</Typography>
										</Item>
									</Stack>
								</Item>
							)}
							<Item
								sx={{
									pt: "19px",
									pb: "24px",
									pl: "16px",
									pr: "16px",
									borderTopRightRadius: 0,
									borderTopLeftRadius: 0,
									backgroundColor: (theme) => {
										let tempColor;
										bonusRelease.Result && bonusRelease.Message.length > 0
											? (tempColor = hexToRGBA(
												theme.palette.mode === "light" ? theme.palette.success.main : theme.palette.primary.main,
												theme.palette.tonalOffset.toString()
											))
											: (tempColor = hexToRGBA(theme.palette.background.paper, "0.1"));
										return tempColor;
									},
									zIndex: bonusRelease.Result && bonusRelease.Message.length > 0 ? 1 : "unset",
								}}
							>
								{bonusRelease.Result === false && (IntegrationSetting.enableReleaseBonus || showNoReleasableText) && (
									<Box
										sx={{
											display: "flex",
											"& > *:not(:last-child)": { marginRight: "3px" },
										}}
									>
										{IntegrationSetting.enableReleaseBonus && (
											<CssTextField
												// inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
												value={bonusUserInput >= 0 ? bonusUserInput : ""}
												required
												placeholder={amountInput}
												/*variant="outlined"*/
												size="small"
												type="number"
												name="bonusamount"
												id="bonusamount"
												autoComplete="number"
												fullWidth
												sx={{
													bgcolor: (theme) => theme.palette.background.default,
												}}
												onChange={handleChange}
												onClick={() => {
													if (!focus) setFocus(true);
												}}
												onBlur={() => {
													if (focus) setFocus(false);
												}}
												autoFocus={focus}
												InputProps={{
													inputProps: { min: 0, max: bonusMaxVal },
													style: {
														margin: "auto",
														borderRadius: 5,
														color: "#000",
													},
													sx: {
														color: (theme) => theme.palette.secondary.main,
														height: "30px",
													},
												}}
												variant="outlined"
											/>
										)}
										{IntegrationSetting.enableReleaseBonus && (
											<Button
												sx={{
													bgcolor: (theme) => theme.palette.background.paper,
													color: (theme) => theme.palette.text.primary,
													height: "30px",
													"&:hover": {
														backgroundColor: (theme) => {
															return theme.palette.background.paper + " !important";
														},
													},
												}}
												fullWidth
												size="small"
												variant="contained"
												onClick={() => {
													requestBonus(releaseByTimer);
												}}
												disabled={error}
											>
												<Trans>Use bonus</Trans>
											</Button>
										)}
										{showNoReleasableText && (
											<Typography sx={{ height: "30px", fontSize: "12px", mb: "1.57px" }}>{GetNoneReleasableText(Components.Bonus, 0)}</Typography>
										)}
									</Box>
								)}
								{IntegrationSetting.enableReleaseBonus && bonusRelease.Result === true && bonusRelease.Message.length > 0 && (
									<Box
										sx={{
											maxHeight: "40px",
											textAlign: "center",
										}}
									>
										<Typography variant="caption" gutterBottom>
											<Trans>{bonusRelease.Message}</Trans>
											<Link
												href="#"
												underline="hover"
												variant="caption"
												component="button"
												onClick={() => {
													changeBonusRequested();
												}}
												color="text.secondary"
												sx={{ pb: "2px", ml: "4px" }}
											>
												<Trans>Change amount?</Trans>
											</Link>
										</Typography>
									</Box>
								)}
							</Item>
							{(IntegrationSetting.enableReleaseBonus || showNoReleasableText) && (
								<Item
									sx={{
										borderTopRightRadius: 0,
										borderTopLeftRadius: 0,
										mt: "-40px",
										height: "40px",
										backgroundColor: (theme) => theme.palette.background.default,
									}}
								></Item>
							)}
						</Stack>
					)}
				</Box>
			)}
		</>
	);
}
